<div [class.container]="isVerticalLayout$ | async" class="toolbar w-full px-gutter flex items-center">
  <button (click)="openSidenav()" [class.hidden]="!mobileQuery" mat-icon-button type="button">
    <mat-icon svgIcon="mat:menu"></mat-icon>
  </button>

  <a *ngIf="isVerticalLayout$ | async"
     [routerLink]="['/']"
     class="ltr:mr-4 rtl:ml-4 block flex items-center">
    <img alt="Logo" class="w-8 select-none" src="assets/img/demo/logo.svg"/>
    <h1 [class.hidden]="!mobileQuery" class="title ltr:pl-4 rtl:pr-4 m-0 select-none">EL SOTANO</h1>
  </a>

  <button *ngIf="isHorizontalLayout$ | async"
          [class.hidden]="mobileQuery"
          [matMenuTriggerFor]="addNewMenu"
          color="primary"
          mat-flat-button
          type="button">{{'add' | translate}}
  </button>

  <mat-menu #addNewMenu="matMenu" [overlapTrigger]="false" xPosition="after" yPosition="below">
    <button mat-menu-item (click)="goTo('/clients/create')">
      <mat-icon svgIcon="mat:person_add"></mat-icon>
      <span>{{'add_client' | translate }}</span>
    </button>

    <button mat-menu-item (click)="addTask()">
      <mat-icon svgIcon="mat:done_all"></mat-icon>
      <span>{{'add_task' | translate }}</span>
    </button>

    <button mat-menu-item (click)="goTo('/bookings/create')">
      <mat-icon svgIcon="mat:assignment_turned_in"></mat-icon>
      <span>{{'add_booking' | translate }}</span>
    </button>
  </mat-menu>

  <mat-menu #documentMenu="matMenu">
    <button mat-menu-item>
      <mat-icon svgIcon="mat:description"></mat-icon>
      <span>Add Quote</span>
    </button>

    <button mat-menu-item>
      <mat-icon svgIcon="mat:assignment"></mat-icon>
      <span>Add Invoice</span>
    </button>

    <button mat-menu-item>
      <mat-icon svgIcon="mat:receipt"></mat-icon>
      <span>Add Receipt</span>
    </button>
  </mat-menu>

  <div #megaMenuOriginRef class="ltr:ml-2 rtl:mr-2" *ngIf="false">
    <button *ngIf="isHorizontalLayout$ | async"
            [class.hidden]="mobileQuery"
            (click)="openMegaMenu(megaMenuOriginRef)"
            [ngClass]="{ 'bg-primary/10': megaMenuOpen$ | async }"
            color="primary"
            mat-button
            type="button">
      <span>Mega Menu</span>
      <mat-icon class="icon-sm" svgIcon="mat:arrow_drop_down"></mat-icon>
    </button>
  </div>


  <div *ngIf="(isVerticalLayout$ | async) && isNavbarInToolbar$ | async"
       [class.hidden]="mobileQuery"
       class="px-gutter flex-none flex items-center">
    <vex-navigation-item *ngFor="let item of navigationItems" [item]="item"></vex-navigation-item>
  </div>

  <span class="flex-1"></span>

  <div class="-mx-1 flex items-center">
    <!--<div class="px-1">
      <button (click)="openSearch()" mat-icon-button type="button">
        <mat-icon color="primary" svgIcon="mat:search"></mat-icon>
      </button>
    </div>-->

    <mat-slide-toggle color="primary" [(ngModel)]="isDarkMode" (change)="toggleDarkMode()">Modo Oscuro</mat-slide-toggle>


    <div class="px-1">
      <vex-toolbar-notifications></vex-toolbar-notifications>
    </div>

    <div class="px-1">
      <button (click)="openQuickpanel()" mat-icon-button type="button">
        <mat-icon color="primary" svgIcon="mat:bookmarks"></mat-icon>
      </button>
    </div>

    <div class="px-1">
      <button [matMenuTriggerFor]="languageMenu" mat-icon-button type="button">
        <mat-icon [svgIcon]="flag"></mat-icon>
      </button>
    </div>

    <div *ngIf="userVisible$ | async" class="px-1">
      <vex-toolbar-user></vex-toolbar-user>
    </div>

    <mat-menu #languageMenu="matMenu" overlapTrigger="false" xPosition="before" yPosition="below">
      <button mat-menu-item (click)="changeLang('flag:france', 'fr')">
        <mat-icon svgIcon="flag:france"></mat-icon>
        <span>{{'france' | translate}}</span>
      </button>

      <button mat-menu-item (click)="changeLang('flag:uk', 'en')">
        <mat-icon svgIcon="flag:uk"></mat-icon>
        <span>{{'english' | translate}}</span>
      </button>

      <button mat-menu-item (click)="changeLang('flag:germany', 'de')">
        <mat-icon svgIcon="flag:germany"></mat-icon>
        <span>{{'germany' | translate}}</span>
      </button>

      <button mat-menu-item (click)="changeLang('flag:spain', 'es')">
        <mat-icon svgIcon="flag:spain"></mat-icon>
        <span>{{'spanish' | translate}}</span>
      </button>

      <button mat-menu-item (click)="changeLang('flag:italy', 'it')">
        <mat-icon svgIcon="flag:italy"></mat-icon>
        <span>{{'italian' | translate}}</span>
      </button>
    </mat-menu>
  </div>
</div>

<vex-navigation *ngIf="(isVerticalLayout$ | async) && isNavbarBelowToolbar$ | async" [class.hidden]="mobileQuery"></vex-navigation>
