<form >
  <div class="flex items-center" mat-dialog-title>
    <h2 class="headline m-0 flex-auto">Add sport</h2>

    <!--<button [matMenuTriggerFor]="settingsMenu" class="text-secondary" mat-icon-button type="button">
      <mat-icon svgIcon="mat:more_vert"></mat-icon>
    </button>-->

    <button class="text-secondary" mat-dialog-close mat-icon-button type="button">
      <mat-icon svgIcon="mat:close"></mat-icon>
    </button>
  </div>

  <mat-divider class="text-border"></mat-divider>

  <mat-dialog-content class="flex flex-col" *ngIf="!loading">
    <div style="width: 100%;">
      <p>El usuario seleccionado no tiene deportes asignados. ¿Te gustaría asignarle deportes ahora?</p>
    </div>

    <mat-divider></mat-divider>

    <div [formGroup]="form" style="margin: 5% 0 0 0" *ngIf="false">
      <div class="flex flex-col sm:flex-row gap-2 sm:gap-6" style="margin: 2% 0 0 0;">
        <mat-form-field appearance="outline" class="flex-auto">
          <mat-label>{{'sports' |translate}}</mat-label>
          <mat-select [formControl]="sportsControl" multiple [disabled]="defaults && defaults.sport_id !== null">

            <mat-select-trigger>
              <div *ngIf="sportsControl.value?.length > 0; else placeholder">
                {{ getSelectedSportsNames() }}
              </div>
              <ng-template #placeholder>{{'choose_sports' | translate }}</ng-template>
            </mat-select-trigger>

            <mat-option *ngFor="let sport of filteredSports | async" [value]="sport" (onSelectionChange)="toggleSelection(sport, $event)">
              {{sport.name}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div class="flex flex-col sm:flex-row gap-2 sm:gap-6">

        <!-- Añadir niños con modal -->
         <mat-table #sportsTable [dataSource]="sportsData" *ngIf="this.selectedSports.length > 0">

           <!-- Otros contenedores según tu descripción -->

           <ng-container matColumnDef="name">
             <mat-header-cell *matHeaderCellDef> {{'name' | translate }}</mat-header-cell>
             <mat-cell *matCellDef="let element">
               <mat-form-field appearance="outline" class="flex-auto" style="margin: 2% 0 0 0;">
                 <mat-label>{{'name' | translate }}</mat-label>
                 <input matInput [value]="element.name" type="text" [readonly]="true">
               </mat-form-field>
             </mat-cell>
           </ng-container>

           <ng-container matColumnDef="level">
             <mat-header-cell *matHeaderCellDef>{{'level' | translate }}</mat-header-cell>
             <mat-cell *matCellDef="let element">
               <mat-form-field appearance="outline" class="flex-auto" style="margin: 2% 0 0 0;">
                <mat-label>{{'level' | translate }}</mat-label>
                <input type="text" placeholder="Pick one" aria-label="Number" matInput [formControl]="levelForm" [matAutocomplete]="autoLevel" [(ngModel)]="element.level"/>
                <mat-autocomplete #autoLevel="matAutocomplete" [displayWith]="displayFnLevel">
                  <ng-container *ngFor="let level of element.degrees" >
                    <mat-option [value]="level" *ngIf="level.active">
                      {{level.annotation}} - {{level.name}}
                    </mat-option>
                  </ng-container>
                </mat-autocomplete>
               </mat-form-field>
             </mat-cell>
           </ng-container>

           <!-- Ícono para eliminar
           <ng-container matColumnDef="delete">
             <mat-header-cell *matHeaderCellDef> Eliminar </mat-header-cell>
             <mat-cell *matCellDef="let element">
               <mat-icon svgIcon="mat:delete" (click)="removeChild(element)"></mat-icon>
             </mat-cell>
           </ng-container> -->

           <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
           <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
         </mat-table>
       </div>
   </div>

  </mat-dialog-content>

  <mat-dialog-actions align="end" >
    <button color="primary" mat-flat-button [mat-dialog-close]="{data: sportsData.data}">Confirmer</button>
    <button color="primary" mat-flat-button [mat-dialog-close]="false">No</button>
  </mat-dialog-actions>
</form>
